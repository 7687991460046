export default {
    name: 'vcs-scroll-spy',
    inserted: function (el, binding, vnode) {
        // Get target id
        const targetId = el.getAttribute('href');

        // Check if it is an ID
        if (!targetId ||
            !targetId.startsWith('#'))
            return;

        // Create a scroll event listener
        document.addEventListener('scroll', function () {
            // Get the target element
            const targetEl = document.querySelector(targetId);

            // Check if the target element exists
            if (!targetEl)
                return;

            // Get the target element's position
            const targetElPosition = targetEl.getBoundingClientRect();

            // Check if the target element is in the viewport
            if (targetElPosition.right > 0 &&
                targetElPosition.bottom > 80 &&
                targetElPosition.left < (window.innerWidth || document.documentElement.clientWidth) &&
                targetElPosition.top < (window.innerHeight || document.documentElement.clientHeight) - 80) {
                // Add the active class
                el.classList.add(binding.value);
            } else {
                // Remove the active class
                el.classList.remove(binding.value);
            }
        });
    },
}