// Init state
const state = () => ({
    localVideos: {},
    videos: {},
    interval: null,
});

// getters
const getters = {
    localVideos: function (state)
    {
        return state.localVideos;
    },
    videos: function (state)
    {
        return state.videos;
    },
};

// actions
const actions = {
    addLocalVideo: function ({ commit }, value) {
        commit('addLocalVideo', value)
    },
    videosIntervalStart: function ({ commit }) {
        if (this.state.vcsVideos.interval === null)
            commit('setInterval', setInterval(() => {
                Vue.axios.get('/api/user/videos')
                    .then(response => {
                        var value = response.data;
                        for (var key in value)
                        {
                            var video = value[key];
                            if (typeof this.state.vcsVideos.videos[video.uuid] == 'undefined' ||
                                this.state.vcsVideos.videos[video.uuid].updated_at != video.updated_at)
                                commit('setVideo', video);
                        }
                        for (var key in this.state.vcsVideos.videos)
                            if (typeof value[key] == 'undefined')
                                commit('deleteVideo', key);
                    })
                    .catch(error => {
                        clearInterval(this.state.vcsVideos.interval);
                        commit('setInterval', null);
                    })
                    .finally(() => {});
            }, 1000));
    },
    videosIntervalStop: function ({ commit }) {
        if (this.state.vcsVideos.interval !== null)
        {
            clearInterval(this.state.vcsVideos.interval);
            commit('setInterval', null);
        }
    },
};

// mutations
const mutations = {
    addLocalVideo: function (state, value) {
        state.localVideos[value.uuid] = value.objectUrl;
    },
    deleteVideo: function (state, key) {
        Vue.delete(state.videos, key);
    },
    setInterval: function (state, value) {
        state.interval = value;
    },
    setVideo: function (state, video) {
        Vue.set(state.videos, video.uuid, video);
    },
    setVideos: function (state, value) {
        for (var key in value)
        {
            var video = value[key];
            if (typeof state.videos[video.uuid] == 'undefined')
                Vue.set(state.videos, video.uuid, video);
            else
                if (state.videos[video.uuid].updated_at != video.updated_at)
                    Vue.set(state.videos, video.uuid, video);
        }
    },
};

// Create module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
