if (!('DragEvent' in window)) {
    // Create a custom DragEvent constructor
    window.DragEvent = function (type, params) {
    };
}

import.meta.glob([
    '../img/**',
    '../video/**',
]);

// Load Vue
import Vue from 'vue/dist/vue.js';

if (!import.meta.env.VITE_MODE ||
    import.meta.env.VITE_MODE !== 'development') {
    Vue.config.productionTip = false;
    Vue.config.devtools = false;
    Vue.config.silent = true;
}

window.Vue = Vue;

import './blank.scss';

// Load Vuex
import Vuex from 'vuex';

Vue.use(Vuex);

// Import store
import store from './store';

window.store = store;

// Full screen plugin
import VueFullscreen from 'vue-fullscreen';

Vue.use(VueFullscreen);

// Load axios
import axios from 'axios';
import VueAxios from 'vue-axios';

function simpleStringify(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}


const buildTimestamp = __BUILD_TIMESTAMP__;

Vue.use(VueAxios, axios);
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.csrf_token
};
// Format nested params correctly
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log('Axios error', JSON.stringify(error), error);
    return Promise.reject(error);
});
axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {
        return simpleStringify(params, {
            arrayFormat: "brackets",
            encode: false
        });
    };

    if (config.url) // Append the unique query parameter
        config.url += `${config.url.includes('?') ? '&' : '?'}_=${buildTimestamp}`;

    return config;
});

Vue.directive('scroll-to-end', {
    inserted: function (el, binding) {
        setTimeout(() => {
            el.scrollTop = el.scrollHeight;
            el.scrollLeft = el.scrollWidth;
        }, 1);
    },
    updated: function (el, binding) {
        setTimeout(() => {
            el.scrollTop = el.scrollHeight;
            el.scrollLeft = el.scrollWidth;
        }, 1);
    },
});

// Import old components
Vue.component('vcs-cart', loadWithRetry(() => import('./components/vcs-cart.vue')));
Vue.component('vcs-search', loadWithRetry(() => import('./components/vcs-search.vue')));

// Import new components
Vue.component('vcs-new-2d-model-inside', loadWithRetry(() => import('./components/vcs-new-2d-model-inside.vue')));
Vue.component('vcs-new-2d-model-inside-left', loadWithRetry(() => import('./components/vcs-new-2d-model-inside-left.vue')));
Vue.component('vcs-new-2d-model-inside-right', loadWithRetry(() => import('./components/vcs-new-2d-model-inside-right.vue')));
Vue.component('vcs-new-2d-model-outside-back', loadWithRetry(() => import('./components/vcs-new-2d-model-outside-back.vue')));
Vue.component('vcs-new-2d-model-side', loadWithRetry(() => import('./components/vcs-new-2d-model-side.vue')));
Vue.component('vcs-new-2d-model-side-component-image', loadWithRetry(() => import('./components/vcs-new-2d-model-side-component-image.vue')));
Vue.component('vcs-new-2d-model-side-component-image-area', loadWithRetry(() => import('./components/vcs-new-2d-model-side-component-image-area.vue')));
Vue.component('vcs-new-2d-model-side-component-text', loadWithRetry(() => import('./components/vcs-new-2d-model-side-component-text.vue')));
Vue.component('vcs-new-2d-model-side-component-textarea', loadWithRetry(() => import('./components/vcs-new-2d-model-side-component-textarea.vue')));
Vue.component('vcs-new-3d-model', loadWithRetry(() => import('./components/vcs-new-3d-model.vue')));
Vue.component('vcs-new-3d-model-container', loadWithRetry(() => import('./components/vcs-new-3d-model-container.vue')));
Vue.component('vcs-new-animation-envelope', loadWithRetry(() => import('./components/vcs-new-animation-envelope.vue')));
Vue.component('vcs-new-animation-confetti', loadWithRetry(() => import('./components/vcs-new-animation-confetti.vue')));
Vue.component('vcs-new-browser', loadWithRetry(() => import('./components/vcs-new-browser.vue')));
Vue.component('vcs-new-camera', loadWithRetry(() => import('./components/vcs-new-camera.vue')));
Vue.component('vcs-new-camera-new', loadWithRetry(() => import('./components/vcs-new-camera-new.vue')));
Vue.component('vcs-new-camera-opener', loadWithRetry(() => import('./components/vcs-new-camera-opener.vue')));
Vue.component('vcs-new-carousel', loadWithRetry(() => import('./components/vcs-new-carousel.vue')));
Vue.component('vcs-new-cart', loadWithRetry(() => import('./components/vcs-new-cart.vue')));
Vue.component('vcs-new-checkout', loadWithRetry(() => import('./components/vcs-new-checkout.vue')));
Vue.component('vcs-new-designers-customize', loadWithRetry(() => import('./components/vcs-new-designers-customize.vue')));
Vue.component('vcs-new-designers-upload', loadWithRetry(() => import('./components/vcs-new-designers-upload.vue')));
Vue.component('vcs-new-modal-discount', loadWithRetry(() => import('./components/vcs-new-modal-discount.vue')));
Vue.component('vcs-new-ecard-response', loadWithRetry(() => import('./components/vcs-new-ecard-response.vue')));
Vue.component('vcs-new-explainer', loadWithRetry(() => import('./components/vcs-new-explainer.vue')));
Vue.component('vcs-new-item-add', loadWithRetry(() => import('./components/vcs-new-item-add.vue')));
Vue.component('vcs-new-item-create', loadWithRetry(() => import('./components/vcs-new-item-create.vue')));
Vue.component('vcs-new-item-customize', loadWithRetry(() => import('./components/vcs-new-item-customize.vue')));
Vue.component('vcs-new-item-ecard', loadWithRetry(() => import('./components/vcs-new-item-ecard.vue')));
Vue.component('vcs-new-paypal-pending', loadWithRetry(() => import('./components/vcs-new-paypal-pending.vue')));
Vue.component('vcs-new-platform-videos', loadWithRetry(() => import('./components/vcs-new-platform-videos.vue')));
Vue.component('vcs-new-product-modal', loadWithRetry(() => import('./components/vcs-new-product-modal.vue')));
Vue.component('vcs-new-reviews-carousel', loadWithRetry(() => import('./components/vcs-new-reviews-carousel.vue')));
Vue.component('vcs-new-scroller', loadWithRetry(() => import('./components/vcs-new-scroller.vue')));
Vue.component('vcs-new-search', loadWithRetry(() => import('./components/vcs-new-search.vue')));
Vue.component('vcs-new-stickers', loadWithRetry(() => import('./components/vcs-new-stickers.vue')));
Vue.component('vcs-new-stickers-giphy', loadWithRetry(() => import('./components/vcs-new-stickers-giphy.vue')));
Vue.component('vcs-new-stock-videos', loadWithRetry(() => import('./components/vcs-new-stock-videos.vue')));
Vue.component('vcs-new-stripe-pending', loadWithRetry(() => import('./components/vcs-new-stripe-pending.vue')));
Vue.component('vcs-new-textareas', loadWithRetry(() => import('./components/vcs-new-textareas.vue')));
Vue.component('vcs-new-video', loadWithRetry(() => import('./components/vcs-new-video.vue')));
Vue.component('vcs-new-video-modal', loadWithRetry(() => import('./components/vcs-new-video-modal.vue')));
// Vue.component('vcs-new-video-upload', loadWithRetry(() => import('./components/vcs-new-video-upload.vue')));
Vue.component('vcs-new-video-upload-aws', loadWithRetry(() => import('./components/vcs-new-video-upload-aws.vue')));
Vue.component('vcs-new-videos', loadWithRetry(() => import('./components/vcs-new-videos.vue')));

Vue.component('vcs-new-tools-make-customizable', loadWithRetry(() => import('./components/vcs-new-tools-make-customizable.vue')));

// Import old components which are re-used in new components
Vue.component('vcs-modal', loadWithRetry(() => import('./components/vcs-modal.vue')));

// Import all directives
import vcsScrollSpy from "./directives/vcs-scroll-spy";

Vue.directive('vcs-clickable-row', loadWithRetry(() => import('./directives/vcs-clickable-row.js')));
Vue.directive('vcs-scroll-spy', vcsScrollSpy);
Vue.directive('vp-lazyload', loadWithRetry(() => import('./directives/vp-lazyload.js')));

// Import addresses components
Vue.component('address-format', loadWithRetry(() => import('../../vendor/svanthuijl/addresses/resources/js/components/address-format.vue')));
Vue.component('address-input', loadWithRetry(() => import('../../vendor/svanthuijl/addresses/resources/js/components/address-input.vue')));
Vue.component('user-addresses', loadWithRetry(() => import('../../vendor/svanthuijl/addresses/resources/js/components/user-addresses.vue')));

// Import auth components
Vue.component('auth-button', loadWithRetry(() => import('../../vendor/svanthuijl/auth/resources/js/components/auth-button.vue')));
Vue.component('auth-email-login', loadWithRetry(() => import('../../vendor/svanthuijl/auth/resources/js/components/auth-email-login.vue')));
Vue.component('auth-guest', loadWithRetry(() => import('../../vendor/svanthuijl/auth/resources/js/components/auth-guest.vue')));
Vue.component('auth-guest-or-user', loadWithRetry(() => import('../../vendor/svanthuijl/auth/resources/js/components/auth-guest-or-user.vue')));
Vue.component('auth-modal', loadWithRetry(() => import('../../vendor/svanthuijl/auth/resources/js/components/auth-modal.vue')));
Vue.component('auth-user', loadWithRetry(() => import('../../vendor/svanthuijl/auth/resources/js/components/auth-user.vue')));


function loadWithRetry(loadComponentFunction, retries = 3, delay = 1000) {
    return () => new Promise((resolve, reject) => {
        const attemptLoad = (attemptNumber) => {
            loadComponentFunction().then(resolve).catch((error) => {
                if (attemptNumber < retries) {
                    setTimeout(() => attemptLoad(attemptNumber + 1), delay);
                } else {
                    reject(error);
                }
            });
        };

        attemptLoad(1);
    });
}