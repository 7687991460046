// Init state
const state = () => ({
    authenticated: null,
    checking: false,
    user: null,
});

// actions
const actions = {
    getAuthStatus ({ commit }, force = false) {
        if ((this.state.auth.user === null && this.state.auth.checking === false) || force)
        {
            commit('setChecking', true);

            Vue.axios.post('/api/auth/check')
                .then(response => {
                    const user = response.data;
                    commit('setAuthenticated', user !== null && user !== false && user.type !== 'guest')
                    commit('setUser', user);
                    commit('setChecking', false);

                    if (window.Sentry)
                        window.Sentry.setUser({ email: user.email, id: user.id, });
                })
                .catch(error => console.log(error));
        }
    }
};

// mutations
const mutations = {
    setChecking(state, value) {
        state.checking = value;
    },
    setAuthenticated(state, value) {
        state.authenticated = value;
    },
    setUser(state, user) {
        state.user = user;
    },
};

// Create module
export default {
    namespaced: true,
    state,
    actions,
    mutations
};