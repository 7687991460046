// Import Vue and Vuex
import Vue from 'vue';
import Vuex from 'vuex';

// Import modules
import auth from '../../../vendor/svanthuijl/auth/resources/js/store/modules/auth'
import vcsVideos from './modules/vcs-videos'

// Register Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        auth,
        vcsVideos,
    },
})
